import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { AuthService } from "@app/shared/services/auth.service";
import { map, catchError, finalize } from "rxjs/operators";
import { FixedService } from "../services/fixed.service";
// import 'rxjs/add/operator/finally';
// import { BlockUI, NgBlockUI } from 'ng-block-ui';
// import { SpinnerService } from "../services/spinner/spinner.service";

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  pendingRequests: number = 0;

  // @BlockUI() blockUI: NgBlockUI;

  constructor(
    public auth: AuthService,
    private router: Router,
    private fixed: FixedService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // increment to track the number of pending requests
    this.pendingRequests++;
    let url = req.url;
    // if(!this._spinner.isShowing() && !url.includes("locations")){
    //     this.blockUI.start();
    // }

    // increment to track the number of pending requests
    if (this.pendingRequests > 0 && !url.includes("notifications")) {
      // this.blockUI.start();
      this.fixed.loading = true;
    }

    // Clone the request to add the new header.
    if (this.auth.getToken()) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.getToken()}`,
        },
      });
    }

    //send the newly created request
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response and headers you want
          if (event.body != null && event.body.code == 401) {
            this.auth.removeToken();
            this.router.navigate(["/login"]);
          } else if (event.body != null && event.body.code == 404) {
            console.log("NOT FOUND");
            this.router.navigate(["/not-found"]);
          }
        }
        return event;
      }),
      finalize(() => {
        this.pendingRequests--;

        if (this.pendingRequests <= 0) {
          // this.blockUI.stop();
          this.fixed.loading = false;
        }
      })
    );
    // .catch((error, caught) => {
    //     if (error.status === 401) {
    //         //logout users, redirect to login page
    //         this.auth.removeToken();
    //     } else {
    //         return Observable.throw(error);
    //     }
    // })
    // .finalize(() => {
    //     this.pendingRequests--;

    //     if(this.pendingRequests <= 0){
    //         // there are no more pending requests
    //         // this.blockUI.stop();
    //     }
    // }) //as any;
  }
}
