import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./shared/containers/header/header.component";
import { SharedModule } from "./shared/shared.module";
import { FullLayoutComponent } from "./shared/containers/full-layout/full-layout.component";
import { SimpleLayoutComponent } from "./shared/containers/simple-layout/simple-layout.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthHttpInterceptor } from "./shared/auth/auth-http.interceptor";
import { AuthGuard } from "./shared/auth/auth.guard";
import { ExamGuard } from "./shared/auth/exam.guard";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FullLayoutComponent,
    SimpleLayoutComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    AuthGuard,
    ExamGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
