import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class FullLayoutComponent {

  constructor() { }
}
