import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "findById" })
export class FindByIdPipe implements PipeTransform {
  transform(list: any[], Id: any, value: any): any {
    if (!list || !Id || !value) {
      return {};
    } else {
      var obj = list.find((s) => s[Id] == value);
      if (obj == null) obj = {};
      return obj;
    }
  }
}
