import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap";
import { AuthService } from "@app/shared/services/auth.service";
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl
} from "@angular/forms";
import { FixedService } from "@app/shared/services/fixed.service";

function confirmPasswordValidator(control: AbstractControl) {
  if (
    control.get("password").value &&
    control.get("password").value != control.get("password_confirm").value
  ) {
    return { confirmPassword: true };
  }

  return null;
}

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  active: any;
  change: any = {};
  modalRef: BsModalRef;
  user: any;
  changeForm: FormGroup;
  returnForm: FormGroup;
  serverError: string;

  constructor(
    public fixed: FixedService,
    private router: Router,
    private modalService: BsModalService,
    private auth: AuthService
  ) {
    this.router.events.subscribe((val: NavigationEnd) => {
      if (this.router.url.includes("exam/")) {
        this.fixed.examHeader = true;
      } else {
        this.fixed.examHeader = false;
      }
      if (val.url != null) {
        this.active = document.URL.split("/")[3];
      }
    });

    this.user = this.auth.getUser();
  }

  ngOnInit() {
    this.changeForm = new FormGroup(
      {
        old_password: new FormControl("", [Validators.required]),
        password: new FormControl("", [
          Validators.required,
          Validators.minLength(8)
        ]),
        password_confirm: new FormControl("")
      },
      [confirmPasswordValidator]
    );

    this.returnForm = new FormGroup({
      password: new FormControl("", Validators.required)
    });
  }

  passwordOperation(template: any) {
    this.changeForm.reset();
    this.change = { oldPassword: "", newPassword: "", reNewPassword: "" };
    this.modalRef = this.modalService.show(template, <ModalOptions>{
      class: "modal-md"
    });
  }

  changePass() {
    this.serverError = "";
    if (this.changeForm.valid) {
      this.auth
        .changePassword(this.changeForm.value)
        .subscribe((response: any) => {
          if (response.code == 200) {
            this.modalRef.hide();
          } else {
            this.serverError = response.message;
          }
        });
    } else {
      this.markFormGroupTouched(this.changeForm);
    }
  }

  logout() {
    this.auth.logOut();
    this.router.navigate(["/login"]);
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  backToManger(template: any) {
    this.returnForm.reset();
    this.serverError = "";
    this.modalRef = this.modalService.show(template, <ModalOptions>{
      class: "modal-md"
    });
  }

  returnConfirm() {
    if (this.returnForm.valid) {
      this.auth
        .checkPassword(this.returnForm.value)
        .subscribe((response: any) => {
          if (response.code == 200) {
            this.modalRef.hide();
            localStorage.removeItem("currentExam");
            localStorage.removeItem("currentPage");
            localStorage.removeItem("currentWorker");
            this.router.navigate(["/home"]);
          } else {
            this.serverError = response.message;
          }
        });
    } else {
      this.markFormGroupTouched(this.returnForm);
    }
  }
}
