import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FixedService {
  public loading: boolean;
  public datePickerConfig: {};
  public examHeader: boolean;
  constructor() {
    this.examHeader = true;
    this.datePickerConfig = {
      dateInputFormat: 'YYYY/MM/DD',
      containerClass: 'theme-orange',
      showWeekNumbers: false
    };
  }
}
