import { CanActivate, Router } from "@angular/router";
import {Injectable} from "@angular/core";

@Injectable()
export class ExamGuard implements CanActivate {

    constructor(private router: Router) {
    }


    canActivate() {
        
        if(localStorage.getItem("currentExam")){
            let exam = localStorage.getItem("currentExam");
            let worker = localStorage.getItem("currentWorker");
            if (!worker) {
                return true;    
            }
            this.router.navigate(["/exam", exam, 'worker', worker]);
            return false;
        }

        return true
    }
}