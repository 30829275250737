import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FullLayoutComponent } from "./shared/containers/full-layout/full-layout.component";
import { SimpleLayoutComponent } from "./shared/containers/simple-layout/simple-layout.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { AuthGuard } from "./shared/auth/auth.guard";
import { ExamGuard } from "./shared/auth/exam.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "",
    component: FullLayoutComponent,
    data: {
      title: "home",
    },
    children: [
      {
        path: "home",
        loadChildren: "./home/home.module#HomeModule",
        canActivate: [ExamGuard],
      },
      {
        path: "notifications",
        loadChildren:
          "./notifications/notifications.module#NotificationsModule",
        canActivate: [ExamGuard],
      },
      {
        path: "workers",
        loadChildren: "./workers/workers.module#WorkersModule",
        canActivate: [ExamGuard],
      },
      {
        path: "exam",
        loadChildren: "./exam/exam.module#ExamModule",
      },
      {
        path: "requests",
        loadChildren: "./requests/requests.module#RequestsModule",
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "",
    component: SimpleLayoutComponent,
    data: {
      title: "login",
    },
    children: [
      {
        path: "",
        loadChildren: "./account/account.module#AccountModule",
      },
      {
        path: "**",
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
