import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  url;
  constructor( private http: HttpClient) {
    this.url = environment.api;
  }

  getToken() {
    // return "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vNTIuMTc0LjIyLjE4OC90b3RhbC9wdWJsaWMvYXBpL2F1dGgvbG9naW4iLCJpYXQiOjE1NTIxMzUyNjIsImV4cCI6Mzc1NTIxMzUyNjIsIm5iZiI6MTU1MjEzNTI2MiwianRpIjoiWFByRld1SGplVHhSMEhDVyIsInN1YiI6MSwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.6rt4jgU4L4giSM_DAma630Hw71vBxYCELcnmcIt2AYw";
    return localStorage.getItem("auth_token_total")
  }

  getUser() {
    return JSON.parse(localStorage.getItem("auth_user_total"));
  }

  isAuthenticated() {
    var token = this.getToken();
    if (token) {
      return true
    } else {
      return false
    }
  }

  getProfile() {
    return this.http.get(this.url + '/profile')
  }

  setToken(token) {
    localStorage.setItem("auth_token_total", token)
  }

  setUser(user) {
    localStorage.setItem("auth_user_total", JSON.stringify(user));
  }

  removeToken() {
    localStorage.removeItem("auth_token_total")
  }

  removeUser() {
    localStorage.removeItem("auth_user_total")
  }

  logOut() {
    this.removeToken();
    this.removeUser();
  }

  login(email, password) {
    return this.http.post(this.url + "/auth/login", { email: email, password: password })
  }

  forgetPassword(data) {
    return this.http.post(this.url + "/auth/forget_password", data);
  }

  resetPassword(data) {
    return this.http.post(this.url + "/auth/reset_password", data);
  }

  validateToken(token, email) {
    return this.http.post(this.url + "/auth/verify_code", {
      token: token,
      email: email
    });
  }

  changePassword(data) {
    return this.http.post(this.url + "/auth/change_password", data);
  }

  checkPassword(data) {
    return this.http.post(this.url + "/auth/check", data);
  }
}
