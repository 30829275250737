import { Component, ViewEncapsulation } from '@angular/core';
import { FixedService } from './shared/services/fixed.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  constructor(public fixed: FixedService) {
  }
}
