import { CommonModule, registerLocaleData } from "@angular/common";
import { NgModule, ModuleWithProviders } from "@angular/core";
import {
  BsDropdownModule,
  ModalModule,
  BsDatepickerModule,
  defineLocale,
  arLocale,
  ProgressbarModule,
} from "ngx-bootstrap";
import { UiSwitchModule } from "ngx-ui-switch";
import { FixedService } from "./services/fixed.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SweetAlert2Module } from "@toverux/ngx-sweetalert2";
import { NgCircleProgressModule } from "ng-circle-progress";
import arSaLocale from "@angular/common/locales/ar-SA";
import { NgSelectModule } from "@ng-select/ng-select";
import { ToastrModule } from "ngx-toastr";
import { FindByIdPipe } from "./pipes/find-by-id.pipe";

const fixedService = new FixedService();

@NgModule({
  declarations: [FindByIdPipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    SweetAlert2Module.forRoot(),
    BsDatepickerModule.forRoot(),
    NgCircleProgressModule.forRoot(),
    ProgressbarModule.forRoot(),
    UiSwitchModule.forRoot({
      size: "small",
    }),
    ToastrModule.forRoot(),
  ],
  exports: [
    CommonModule,
    BsDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule,
    ModalModule,
    SweetAlert2Module,
    NgCircleProgressModule,
    BsDatepickerModule,
    NgSelectModule,
    ProgressbarModule,
    ToastrModule,
    FindByIdPipe,
  ],
  providers: [{ provide: FixedService, useValue: fixedService }],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
    };
  }
}

registerLocaleData(arSaLocale);
defineLocale("ar", arLocale);
